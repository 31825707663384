<template>
  <b-modal
    id="modal-template-instruction"
    ref="refModal"
    :title="t('Hướng dẫn tạo mẫu')"
    :hide-header-close="true"
    hide-footer
    size="lg"
  >
    <!-- Body -->
    <div>123</div>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BModal,
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>
